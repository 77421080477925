<template>
  <div class="main noScroll">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            key="parent">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between"
             v-if="currentTabIndex!==6">
          <div class="flex">
            <template v-if="currentTabIndex===0">
              <Select v-model="selectType"
                      placeholder="查询分类"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in selectTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onSelectDepartment">
              </MyTreeSelect>
              <Select v-model="remoteFacl"
                      filterable
                      clearable
                      placeholder="请输入设施名称"
                      :remote-method="remoteMethod"
                      :loading="remoteFaclLoading"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteFacl">
                <Option v-for="option in remoteFaclList"
                        :value="option.name"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <!-- <MyTreeSelect :value="faclName"
                            placeholder="设施名称"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="faclArr"
                            search
                            @onSelectChange="onSelectFaclArr">
              </MyTreeSelect> -->
              <DatePicker :value="date"
                          type="daterange"
                          :start-date="new Date()"
                          :clearable="false"
                          :editable="false"
                          placeholder="请选择时间范围"
                          :options="dateOptions"
                          @on-change="onChangeDate"
                          style="width:260px"
                          v-if="selectType===1"></DatePicker>
              <Monthrange :itemStyle="{width:'160px','margin-right':'10px'}"
                          :dateMonthrange="dateMonthrange"
                          @onChange="onChangeMonthrange"
                          v-show="selectType===2"></Monthrange>
            </template>
          </div>
          <div class="flex">
            <template v-if="selectType===1">
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/faclReport/query'])"
                      :ghost="buttonIndex == 2 ? false : true"
                      @click.stop="onClickNearlyThreeDay">近三天</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/faclReport/query'])"
                      :ghost="buttonIndex == 0 ? false : true"
                      @click.stop="onClickNearlySevenDay">近七天</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/faclReport/query'])"
                      :ghost="buttonIndex == 1 ? false : true"
                      @click.stop="onClickCurrentMonth">本月</Button>
            </template>
            <template v-if="selectType===2">
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/faclReport/query'])"
                      :ghost="buttonIndex == 3 ? false : true"
                      @click.stop="onClickPreMonthDay(3)">近三月</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/faclReport/query'])"
                      :ghost="buttonIndex == 4 ? false : true"
                      @click.stop="onClickPreMonthDay(6)">近六月</Button>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/faclReport/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/faclReport/query'])"
                    @click.stop="onResetSearchData">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="currentTabIndex===0">
        <div class="section">
          <Tabs v-model="currentPublicToiletEchartsTabIndex"
                class="tabs"
                key="childEcharts1">
            <TabPane v-for="item in publicToiletEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts ref="publicToiletRef"
                   height="400px"
                   :options="totalOptions"
                   :data="publicToiletEchartsData"
                   v-show="publicToiletEchartsData.length"></Echarts>
          <div class="noData"
               v-show="publicToiletEchartsData.length===0">
            <img src="@/assets/common/tubiaono.png">
            暂无数据
          </div>
        </div>
      </template>
      <div class="section">
        <div class="flex a-center j-between">
          <template v-if="currentTabIndex===0">
            <Tabs v-model="currentPublicToiletTabIndex"
                  class="tabs"
                  key="child1">
              <TabPane v-for="item in PublicToiletTabArr"
                       :key="item.name"
                       :label="item.name"
                       :disabled="currentPublicToiletTabIndex == 0"></TabPane>
            </Tabs>
          </template>
          <Dropdown v-if="checkPermi(['/admin/faclReport/export'])">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="part">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('part')">
                  <Button>
                    导出部分
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="now">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('now')">
                  <Button>
                    导出本页
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="all">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('all')">
                  <Button>
                    导出全部
                  </Button>
                </ExportTemplate>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="table">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="getData"
                 border
                 stripe
                 :loading="tableLoading"
                 :row-class-name="rowClassName"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <template slot-scope="{ row }"
                      slot="operation">
              <Button @click.stop=""
                      type="text">处理</Button>
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from '@/components/common/Echarts';
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { getToday, getYesterday, getNearlyThreeDay, getCurrentMonth, getLastMonth, getYearOrMonthForMonth, getPreMonthDay, getCurrentWeek, formatDate, getNearlySevenDay, getCurrentYearToNow } from '@/utils/dateRangUtil'
import Monthrange from '@/components/common/Monthrange'
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
export default {
  components: { Echarts, MyTreeSelect, Monthrange, ExportTemplate },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return date && date > new Date()
        }
      },
      currentTabIndex: 0,
      tabArr: [
        {
          name: '公厕人流统计'
        },
      ],
      carNumber: '',
      selectType: 1,
      selectTypeArr: [
        {
          id: 1,
          name: '按天查询'
        },
        {
          id: 2,
          name: '按月查询'
        },
      ],
      faclId: '',
      faclName: '',
      faclArr: [],
      remoteFacl: '',
      remoteFaclList: [],
      remoteFaclLoading: false,
      departmentId: '',
      departmentName: '',
      date: [],
      dateMonthrange: [],
      currentPublicToiletEchartsTabIndex: 0,
      publicToiletEchartsTabArr: [
        {
          name: '人流量统计图'
        },
      ],
      currentPublicToiletTabIndex: 0,
      PublicToiletTabArr: [
        {
          name: '各公厕人流统计'
        },
        {
          name: '统计明细'
        },
      ],
      totalOptions: {
        dataset: {
          source: []
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          name: '单位：次',
          minInterval: 1,
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [{
          type: 'line',
          // smooth: true,
        }],
        color: ['#25bb96'],
      },
      publicToiletEchartsData: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      publicToiletAllData: [],
      columnsPublicToiletAll: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施名称',
          key: 'faclname',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickFaclNameDetail(row)
                }
              }
            }, row.faclname);
          }
        },
        {
          title: '状态',
          key: 'faclstatus',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let statusStr
            switch (row.faclstatus) {
              case '0':
                statusStr = '启用'
                break;
              case '1':
                statusStr = '废弃'
                break;
              case '2':
                statusStr = '维修'
                break;
              case '3':
                statusStr = '拆除'
                break;
              case '4':
                statusStr = '暂停'
                break;
              default:
                break;
            }
            return h('span', statusStr)
          }
        },
        {
          title: '人流量',
          key: 'personcount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '人流总量',
          key: 'personcountall',
          tooltip: true,
          align: 'center'
        },
      ],
      publicToiletRefuelData: [],
      columnsPublicToiletRefuel: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          fixed: 'left',
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施名称',
          key: 'faclname',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '人流量',
          key: 'personcount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '日期',
          key: 'toiletpersondate',
          tooltip: true,
          align: 'center'
        },
      ],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      isSummaryClick: false,
      buttonIndex: null,
      todayArr: []
    };
  },
  watch: {
    currentTabIndex () {
      this.selectArr = []
      this.resetSearchData()
      this.onClickSearch()
    },
    selectDataArr (newVal) {
      console.log(newVal);
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            if (this.currentPublicToiletTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.id == items.id
              })
              this.selectIndexArr.push(data.id)
            }
            if (this.currentPublicToiletTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.toiletpersondate == items.toiletpersondate
              })
              this.selectIndexArr.push(data.toiletpersondate)
            }
          }

        })
      }
    },
    currentPublicToiletTabIndex (newVal) {
      this.selectArr = []
      if (newVal == 0) {
        if (this.isSummaryClick) {
          this.remoteFacl = ''
          this.faclName = ''
          this.faclId = ''
          this.isSummaryClick = false
        }
      }
      this.onClickSearch()
    },
    selectType (newVal) {
      if (newVal == 1) {
        this.onClickCurrentMonth()
      } else {
        this.onClickCurrentYear()
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentPublicToiletTabIndex) {
            case 0:
              columns = this.columnsPublicToiletAll
              break;
            case 1:
              columns = this.columnsPublicToiletRefuel
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getData () {
      let data = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentPublicToiletTabIndex) {
            case 0:
              data = this.publicToiletAllData
              break;
            case 1:
              data = this.publicToiletRefuelData
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return data
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentPublicToiletTabIndex) {
            case 0:
              path = this.$http.exportPublicToiletReportForm
              break;
            case 1:
              path = this.$http.exportPublicToiletDetailReportForm
              break;

            default:
              break;
          }
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateDepartmentTreeList()
      this.getPublicToiletList()
      this.todayArr = getToday().split('-')
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // 获取公厕列表
    getPublicToiletList () {
      this.$http.getFacilityBigTypeAllList({
        type: 'facl'
      }).then(res => {
        if (res.code === 200) {
          let data = res.result.find(item => item.name == '公厕')
          this.$http.getBindObjectTreeList({ treetype: data.id }).then(ress => {
            if (ress.code === 200) {
              this.faclArr = ress.result
            }
          })
        }
      })
    },
    // 设施名称选择器回调
    onSelectFaclArr (section) {
      if (section.type !== 'depart') {
        this.faclId = section.id
        this.faclName = section.name
      }
    },
    // 修改所属机构
    onSelectDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 日期选择器回调
    onChangeDate (date) {
      let millisecond = 1000 * 60 * 60 * 24
      let startDate = new Date(date[0]).getTime()
      let endDate = new Date(date[1]).getTime()
      if (endDate - startDate < millisecond * 2) {
        this.date = getCurrentMonth(false)
        this.$Message.info('最少选择三天')
      } else {
        this.date = date
      }
      this.buttonIndex = null
    },
    // 月份回调
    onChangeMonthrange (arr) {
      let startMonthArr = arr.month[0].split('-')
      let endMonthArr = arr.month[1].split('-')
      if (parseInt(endMonthArr[1]) - parseInt(startMonthArr[1]) >= 2) {
        this.date = arr.day
      } else {
        this.date = []
        this.dateMonthrange = []
        this.$Message.info('最少选择三个月')
      }
      this.buttonIndex = null
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay(false)
      this.getList()
      this.buttonIndex = 2
    },
    // 近七天
    onClickNearlySevenDay () {
      this.date = getNearlySevenDay(false)
      this.getList()
      this.buttonIndex = 0
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth(false)
      this.buttonIndex = 1
      let millisecond = 1000 * 60 * 60 * 24
      if (new Date(this.date[1]).getTime() - new Date(this.date[0]).getTime() < millisecond * 2) {
        this.$Message.info('最少选择三天')
        this.publicToiletEchartsData = []
        this.publicToiletAllData = []
        this.publicToiletRefuelData = []
        this.totalPage = 0
        return
      }
      this.getList()
    },
    // 近几个月
    onClickPreMonthDay (num) {
      this.date = getPreMonthDay(num)
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
      switch (num) {
        case 3:
          this.buttonIndex = 3
          break;
        case 6:
          this.buttonIndex = 4
          break;
        default:
          break;
      }
    },
    // 本年
    onClickCurrentYear () {
      this.date = getCurrentYearToNow()
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.currentTabIndex === 0) {

        if (this.currentPublicToiletTabIndex === 0) {
          if (this.selectIndexArr.includes(row.id)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentPublicToiletTabIndex === 1) {
          if (this.selectIndexArr.includes(row.toiletpersondate)) {
            return 'table-bgcolor'
          }
        }

      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getPublicToiletEcharts()
          switch (this.currentPublicToiletTabIndex) {
            case 0:
              this.getPublicToiletStatistics()
              break;
            case 1:
              this.getPublicToiletDetail()
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    // 获取公厕echarts
    getPublicToiletEcharts () {
      let params = {
        facldeptid: this.departmentId,    // 机构id
        faclname: this.remoteFacl ? this.remoteFacl.split('[')[0] : '',          // 设施名称
        depId: this.departmentId
      }
      // 按月查询
      if (this.selectType == 2) params.flag = 1
      this.dateFormat(params)
      this.$http.getPublicToiletEcharts(params).then((res) => {
        if (res.code == 200) {
          this.publicToiletEchartsData = res.result
        }
      })
    },
    // 获取公厕统计列表
    getPublicToiletStatistics () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        facldeptid: this.departmentId,    // 机构id
        faclname: this.remoteFacl ? this.remoteFacl.split('[')[0] : '',          // 设施名称
        depId: this.departmentId
      }
      this.dateFormat(params)
      this.tableLoading = true
      this.$http.getPublicToiletStatistics(params).then((res) => {
        if (res.code == 200) {
          this.publicToiletAllData = res.result.factypelist
          this.totalPage = res.result.total
          this.tableLoading = false
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取公厕明细列表
    getPublicToiletDetail () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        facldeptid: this.departmentId,    // 机构id
        faclname: this.remoteFacl ? this.remoteFacl.split('[')[0] : '',          // 设施名称
        depId: this.departmentId
      }
      this.dateFormat(params)
      this.tableLoading = true
      this.$http.getPublicToiletDetail(params).then((res) => {
        if (res.code == 200) {
          this.publicToiletRefuelData = res.result.toiletPersonMXlist
          this.totalPage = res.result.total
          this.tableLoading = false
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击设施名称
    onClickFaclNameDetail (row) {
      this.isSummaryClick = true
      // this.remoteFacl = row.faclname
      this.faclName = row.faclname
      this.faclId = row.id
      this.remoteFacl = row.faclname
      console.log(this.remoteFacl);
      this.currentPublicToiletTabIndex = 1
    },
    // 统一格式化开始日期 结束日期
    dateFormat (params) {
      if (this.date[0]) {
        params.startTime = this.date[0]
        params.endTime = this.date[1]
      }
    },
    // 设施名远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteFaclLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchFaclList(params).then((res) => {
          if (res.code === 200) {
            this.remoteFaclLoading = false
            let data = res.result
            this.remoteFaclList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteFaclLoading = false
            this.remoteFaclList = [];
          }
        })
      } else {
        this.remoteFaclList = [];
      }
    },
    onQueryChangeRemoteFacl (value) {
      if (!value) {
        this.remoteFaclList = []
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.pageSize = 20
      this.getList()
    },
    // 重置按钮
    onResetSearchData () {
      this.resetSearchData()
      this.onClickCurrentMonth()
    },
    // 重置数据
    resetSearchData () {
      this.selectType = 1
      this.departmentId = ''
      this.departmentName = ''
      this.faclId = ''
      this.faclName = ''
      this.remoteFacl = ''
      this.date = []
      this.dateMonthrange = []
      this.buttonIndex = null
      switch (this.currentTabIndex) {
        case 0:
          this.currentPublicToiletEchartsTabIndex = 0
          this.currentPublicToiletTabIndex = 0
          break;
        default:
          break;
      }
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          this.dateFormat(data)
          if (value == 'now') {
            if (this.currentPublicToiletTabIndex == 0) {
              data.idlist = this.getData.map(item => {
                return item.id
              })
            } else {
              data.id = this.faclId
              data.toiletpersondate = this.getData.map(item => {
                return item.toiletpersondate
              })
            }
          } else if (value == 'part') {
            if (this.currentPublicToiletTabIndex == 0) {
              data.idlist = this.selectArr.map(item => {
                return item.id
              })
            } else {
              data.id = this.faclId
              data.toiletpersondate = this.selectArr.map(item => {
                return item.toiletpersondate
              })
            }
          } else {
            if (this.currentPublicToiletTabIndex == 1) {
              data.id = this.faclId
              data.toiletpersondate = this.selectArr.map(item => {
                return item.toiletpersondate
              })
            }
          }
          break;
        default:
          break;
      }
      return data
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.noData {
  height: 400px;
  font-size: 16px;
  img {
    width: 150px;
    height: 100px;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow: auto;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
      .topbar-search-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .topbar-search-item-key {
          width: 70px;
          text-align: right;
          color: #a4a4a4;
        }
      }
    }
  }
  .content {
    .section {
      padding: 0px 15px 15px 15px;
      background-color: #fff;
      margin-top: 16px;
      .section-title {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #ddd;
        span {
          font-weight: 700;
          font-size: 16px;
          padding-left: 10px;
          border-left: 2px solid #25bb96;
        }
      }
      .section-list {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .section-item {
          span {
            font-weight: 700;
          }
        }
      }
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>